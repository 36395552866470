body {
  font-family: 'Raleway', sans-serif;
  letter-spacing: 0.098em;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
   height: 100vh; 
   text-align:center; 
} 


button {
  padding: 10px 20px;
  background-color: #0197B0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #017c91;
}


.card {
  width: 90%;
  height: auto;
  padding: 60px 0;
}

.fleX {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}

.max-width {
  max-width: 1400px;
  margin: auto;
}

.some-class {
  pointer-events: none;
}

.card__header-title {
  width: 100%;
  font-size: 32px;
  font-weight: 500;
  color: #484848;
}

.card__title {
  font-size: 36px;
  font-weight: 600;
  color: #484848;
}

.card__content {
  font-size: 25px;
  font-weight: 400;
  color: #878787;
  text-align: justify;
}


.card_L img {
  height: 253px;
  border-radius: 15px;
  background-size: cover;
  background-attachment: fixed;
}

.imG {
  justify-content: flex-end;
  align-items: end;
  flex-direction: column;
}

.iMG {
  width: 150px;
  height: 20px !important;
  position: relative;
  top: 25px;
}

.features_b {
  border-bottom: 1px solid #D4D4D4;
}

.features_img_S {
  width: 304px;
  background: #3D3A3A;
  padding: 25px;
  border-radius: 16.4px;
  flex-direction: column;
  gap: 10px;
}

.features_img_S1 {
  width: 100%;
  font-size: 20.78px;
  font-weight: 600;
  color: #C9C9C9;
}

.features_img_S2 {
  width: 100%;
  font-size: 15.31px;
  font-weight: 600;
  color: #0197B0;
}

.continuous_Us_Img {
  width: 336px;
  height: 590px;
  border-radius: 15px;
  overflow: auto;
}

.continuous_Us_ {
  flex-direction: column;
  width: 400px;
  height: 590px;
  justify-content: center;
  padding: 60px 30px;
  border: 1px solid #D4D4D4;
  border-top: none;
  border-bottom: none;
}

.howItWorks_List {
  background: linear-gradient(to bottom, #01505D, #0097AF);
  padding: 100px 80px;
  border-radius: 50px;
  flex-direction: column;
  grid-gap: 50px;
  gap: 80px;
}

.howItWorks_T {
  font-size: 24px;
  font-weight: 600;
  color: #FFFFFF;
}

.howItWorks_C,
.fa-Q {
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: justify;
}

.hIW_Lt_IMG,
.isActive {
  width: 100px;
  height: 100px;
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 15px;
}

.hIW_Lt_IMG img,
.isActive img {
  height: 35px;
  padding: 0 30px;
}

.howItWorks_List_IMG {
  height: 100px;
}

.hIW_Lt_IMG:hover,
.isActive {
  background: #FFFFFF;
}

.hIW_Lt_IMG:hover .IMG_C,
.isActive .IMG_C,
.IMG_T {
  display: flex;
}

.hIW_Lt_IMG:hover .IMG_T,
.isActive .IMG_T,
.IMG_C {
  display: none;
}

.bar_p {
  position: relative;
  top: 40px;
}

.peopleSayIN {
  width: 100%;
  height: 575px;
  background: #0191A999;
  border-radius: 25px;
  background-image: url('./assets/SayIn/pep.webp');
  background-position: center;
  background-size: cover;
}

.peopleSayIN-s {
  width: 100%;
  height: 575px;
  background: #0191A999;
  border-radius: 25px;
  justify-content: space-evenly;
}

.say-Note {
  width: 390px;
  height: 435px;
  border: 1px solid #FFFFFF1A;
  background: #FFFFFF33;
  border-radius: 25px;
  font-size: 20px;
  line-height: 24px;
}

.say-Title {
  font-size: 28px;
  font-weight: 700;
  color: #FFFFFF;
}

.say-Note {
  font-weight: 600;
  color: #FFFFFF;
}

.vs-font {
  font-size: 14px;
  font-weight: 500;
  color: #5B5B5B;
}

.acc_Content {
  width: calc(100% - 100px);
  height: 80px;
  display: flex;
  align-items: center;
  border: none;
  border-bottom: 1px solid #575757;
  transition: transform 0.3s;
}

.open_Acc {
  height: 150px;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 25px;
  gap: 10px;
  color: #fff;
  border-bottom: unset;
  background: linear-gradient(to bottom, #01505D, #0097AF);
}

.faq_F {
  z-index: 9;
  position: relative;
  top: 15px;
}

.acc_D_I_Sec {
  width: 50px;
  height: 50px;
  background: linear-gradient(to bottom, #01505D, #0097AF);
  color: #ffff;
  border-radius: 50%;
  justify-content: center;
}

.droP {
  font-size: 20px;
  color: #ffff;
  justify-content: center;
}

.p-accordion-toggle-icon {
  display: none;
}

.exPnd {
  width: 40px;
  height: 40px;
  background: none;
  border: 1px solid #696969;
}

.p-Accordion {
  flex-direction: column;
  justify-content: unset;
  align-items: unset;
  gap: 25px;
}

:focus-visible {
  outline: unset !important;
}

.ban-Le {
  width: 100%;
  background: #FFFFFF;
  padding: 0 25px;
}

.ban-Ri {
  width: 100%;
  background: linear-gradient(to bottom, #01505D, #0097AF);
  border-radius: 0 0 0 50px;
  padding: 170px 0;
}

.ban-C1 {
  width: 323px;
  color: #323232;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.3px;
}

.ban-C2 {
  color: #878787;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.3px;
}

.ban-C3 {
  background: linear-gradient(to bottom, #01505D, #0097AF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: 54px;
  font-weight: 700;
  letter-spacing: -0.3px;
}

.ban-C4 {
  color: #959595;
  font-size: 18px;
  font-weight: 400;
  padding: 25px 0;
  line-height: 21px;
}

.int-Sec {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.int-Sec label {
  width: 80%;
  font-size: 18px;
  font-weight: 600;
  color: #FFFFFF;
}

.in_Box,
.p-dropdown-label {
  width: 80%;
  height: 40px;
  background: transparent;
  box-shadow: none;
  color: #FFFFFF;
  border: none;
  border-bottom: 1px solid #FFFFFF;
  border-radius: unset;
}

.p-dropdown-label {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
}

.p-dropdown-items {
  color: #FFFFFF;
  background: #ffffff2e;
  font-size: 14px;
  font-weight: 400;
  padding: 15px;
  border-radius: 0 0 15px 15px;
}

.p-dropdown-item {
  height: 25px;
  display: flex;
  align-items: center;
}

.p-error {
  width: 80%;
  color: rgb(244, 67, 54);
}

.in_Box .menu-p {
  width: 560px;
  padding: 15px 10px;
  background: linear-gradient(to bottom, #01505D, #0097AF);
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.in_Box .menu-p :hover {
  background: #01505D;
}


.menu-p_B .p-button-label {
  text-align: justify;
}

.b_BTN {
  width: 133px;
  height: 57px;
  border: none;
  border-radius: 25px;
  background: #FFFFFF;
}

.b_BTN .p-button-label {
  color: #01505D;
  font-size: 16px;
  font-weight: 600;
}

.w-full {
  width: 100%;
}

.b_Menu {
  margin: auto;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  position: relative;
  box-shadow: 0px 4px 10px #0000001A;
}

.meNU_BTN {
  width: 180Px;
  font-size: 18px;
  font-weight: 500;
  padding: unset;
  margin: unset;
  background: unset;
  border: unset;
  color: #747272;
}

.meNU_BTN:hover,
.mBTN-h {
  color: #0197B0;
  font-weight: 600;
}

.card-Pad {
  padding: 100px 20px;
}

.fOO-t {
  height: 100px;
  gap: 25px;
}

.vs-fontB {
  font-size: 18px;
  font-weight: 700;
}

.ga-HTW {
  gap: 50px;
}

.hed_gaP {
  gap: 60px !important;
}

.ha-M {
  font-size: 30px;
  color: #ffff;
  background: #0191A999;
  padding: 5px;
  border-radius: 5px;
}

.nex {
  height: 35px;
}

.nex-Sec {
  justify-content: flex-end;
  position: relative;
  left: 80px;
}

.ham-Berger {
  position: absolute;
  right: 25px;
  background: #ffff;
  width: fit-content;
  height: auto;
  padding: 15px 25px;
  border-radius: 6px;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  box-shadow: 0px 4px 10px #0000001A;
}

.card_R {
  max-width: 50%;
}

.d-OB {
  position: absolute;
  right: 0;
  top: 462vh;
  height: 170vh;
}

.cursor {
  cursor: pointer;
}


/* Lap */

@media (max-width:1600px) {

  .l-font-14 {
    font-size: 14px;
  }

  .l-font-16 {
    font-size: 16px;
  }

  .l-font-18 {
    font-size: 18px;
  }

  .l-font-20 {
    font-size: 20px;
  }

  .card-Pad {
    padding: 60px 20px;
  }

  .card__title {
    font-size: 25px;
  }

  .card__content {
    font-size: 20px;
  }

  .fOO-t {
    padding: 0 25px;
  }

  .card__header-title {
    font-size: 25px;
  }


  .say-Note {
    width: 320px;
    height: 350px;
    font-size: 18px;
  }

  .nex {
    height: 25px;
  }

  .d-OB {
    top: 451vh;
  }


}

/* Tab */

@media (max-width:1100px) {


  .t-font-14 {
    font-size: 14px;
  }

  .t-font-18 {
    font-size: 18px;
  }

  .t-font-20,
  .ban-C2 {
    font-size: 20px;
  }

  .card__header-title {
    font-size: 20px;
  }

  .h-25 {
    height: 25px;
  }

  .fOO-t {
    height: auto;
    gap: 15px;
    flex-direction: column;
    padding: 25px;
  }

  .say-Note {
    font-size: 16px;
  }

  .faq_F img {
    width: 250px;
  }

  .foNT-S-M {
    font-size: 16px;
  }

  .foNT-m-M {
    font-size: 18px;
  }

  .ban-C1 {
    font-size: 35px;
  }

  .ban-C3 {
    font-size: 40px;
  }

  .f-Direction-L {
    flex-direction: column;
  }

  .faq_F img {
    width: 200px;
  }

  .meNU_BTN {
    width: 155Px;
    font-size: 16px;
  }
}

@media (max-width:800px) {

  .fleX {
    gap: 18px;
  }

  .card {
    flex-wrap: wrap;
    justify-content: center;
  }

  .card_R {
    max-width: 100%;
  }

  .card_R,
  .card_L {
    width: 100%;
  }

  .card__title {
    font-size: 20px;
  }

  .card_L img {
    height: 200px;
  }

  .say-Note {
    width: 85%;
    height: 250px;
  }

  .f-Direction-T {
    flex-direction: column;
  }

  .howItWorks_List {
    padding: 100px 30px;
  }

  .howItWorks_C {
    height: 120px;
    overflow: auto;
  }

  .bar_p {
    position: relative;
    top: 10px;
  }

  .foNT-S-M {
    font-size: 14px;
  }

  .foNT-m-M,
  .int-Sec label {
    font-size: 16px;
  }

  .hIW_Lt_IMG,
  .isActive {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }

  .hIW_Lt_IMG img,
  .isActive img {
    height: 25px;
    padding: 0 27px;
  }

  .continuous_Us_ {
    height: auto;
  }

  .continuous_Us_Img {
    display: none;
  }

  .jeS-C {
    justify-content: center;
  }

  .ban-Le {
    width: 90%;
  }

  .ban-Ri {
    padding: 100px 0;
  }

  .nex {
    height: 25px;
  }

  .faq_F img {
    width: 200px;
  }

  .faq_F img {
    width: 130px;
  }

  .d-OB {
    top: 458vh;
    /* height: 115vh; */
  }
}

/* Mob */

@media (max-width:575px) {

  .fleX,
  .ga-HTW {
    gap: 25px;
  }

  .gaP-2 {
    gap: 2px;
  }

  .vs-font {
    font-size: 12px;
  }

  .m-Warp {
    flex-wrap: wrap;
    justify-content: center;
  }

  .say-Note {
    width: 75%;
    height: 220px;
    border-radius: 15px;
  }

  .card__header-title {
    font-size: 18px;
  }

  .fOO-t {
    gap: 15px !important;
  }

  .comment {
    height: 65px;
  }

  .fa-Q-C,
  .comment {
    overflow: auto;
  }

  .hIW_Lt_IMG,
  .isActive {
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }

  .hIW_Lt_IMG img,
  .isActive img {
    height: 18px;
    padding: 0 20px;
  }

  .howItWorks_List {
    border-radius: 25px;
    gap: 50px;
  }

  .hed_gaP {
    gap: 35px !important;
  }

  .acc_Content {
    width: calc(100% - 50px);
    text-align: left;
  }

  .acc_D_I_Sec {
    width: 35px;
    height: 35px;
  }

  .exPnd {
    width: 30px;
    height: 30px;
  }

  .foNT-S-M,
  .int-Sec label,
  .foNT-m-M {
    font-size: 14px;
  }

  .foNT-l-M {
    font-size: 16px;
  }

  .ban-C3 {
    font-size: 30px;
  }

  .mob-SEC {
    width: 225px;
  }

  .mob-SEC img {
    height: 130px;
  }

  .faq_F img {
    display: none;
  }

  .d-OB {
    top: 513vh;
    height: 69vh;
  }

}

@media (max-width:420px) {

  .open_Acc {
    height: auto;
  }

  .ban-C1 {
    width: -webkit-fill-available;
  }

  .m-IMG {
    width: 90%;
  }
}

@media (max-width:360px) {

  .howItWorks_List {
    padding: 100px 20px;
  }
}

@media (max-width:319px) {

  .m-hide,
  .d-OB {
    display: none;
  }

  .say-Note {
    width: 66%;
    height: 90px;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 575px) and (max-width: 870px) {
  .d-OB {
    top: 565vh;
    height: 97vh;
  }
}

@media only screen and (min-width: 420px) and (max-width: 575px) {
  .d-OB {
    top: 590vh;
    height: 65vh;
  }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .d-OB {
    top: 653.5vh;
    height: 60vh;
  }
}